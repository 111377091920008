import React from 'react';
import ReactDOM from 'react-dom';
import WebLogin from './containers/weblogin/WebLogin';
import {Provider} from 'react-redux'
import {ConnectedRouter} from 'connected-react-router'
import store, {history} from './store'
import './index.css';
import '@imprivata/common-auth-ui/build/common-auth-ui.css'
import '@imprivata/nucleus/build/nucleus.css'

ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <WebLogin/>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);
